
import { Link } from "react-router-dom"
import Iubenda from "react-iubenda-policy"

import "../css/Footer.css"

export default function Footer() {

    const Policy = () => {
        const myPolicy = 64322309 // your policy id
        return (
          <div>
      
            {/* Renders the Privacy Policy link with the text 'Privacy Policy' */}
            <Iubenda id={myPolicy}/>
      
            <Iubenda id={myPolicy} type='privacy' styling='white'>
              Privacy Policy
            </Iubenda>
      
            <Iubenda id={myPolicy} type='cookie' styling='black'>
              Cookie Policy
            </Iubenda>
          </div>
        )
      }

    return (
        <footer>
            <section className="footer--info">
            <div>
                <h4>Carrozzeria Bulletti di Bulletti Mirco</h4>
                <p>Carrozzeria Bulletti Mirco S.R.L. Unipersonale</p>
                <p>Loc. Pratacci, Via Louis Pasteur 9 52100 Arezzo (AR)</p>
                <p>P.IVA 02361950518</p>
            </div>
            <div>
                <h4>Contatti</h4>
                <p>email: info@bulletticarrozzeria.it</p>
                <p>Telefono: 0575 6294364</p>
                <p>Cellulare: +39 335 6294364</p>
            </div>
            <div>
                <Link to="../contatti">
                    <button className="btn btn-lg btn-outline-dark" id="cta--footer">Contattaci</button>
                </Link>
                <br/>
                {Policy}
            </div>
            </section>
            <div className="copyright">
            <p><small>© 2024 bulletticarrozzeria.it</small></p>
            </div>
        </footer>
    )
}